<template>
  <div>
    <div class="card bg-card p-1">
      <div class="row mb-1">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center">
            <input
              v-model="searchPeriodic"
              placeholder="Search"
              class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
              style="max-width: 200px"
            />
            <button
              class="btn btn-outline-primary float-right btn-sm mt-1 mb-1 mr-1"
              v-b-toggle.add-activity
              :disabled="!this.$store.state.auth.permissions.includes('periodic_activity-create')"
            >
              + Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card p-2">
        <div class="row">
          <div class="col-md-12">
            <table class="table border rounded">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Frequency</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(act, index) in this.activities" :key="act._id">
                  <td scope="row">
                    {{ (currentPage - 1) * perPage + index + 1 }}
                  </td>
                  <td>{{ act.activity }}</td>
                  <td>
                    <span v-if="act.frequency == '1'">Hourly</span>
                    <span v-if="act.frequency == '2'">Daily</span>
                    <span v-if="act.frequency == '3'">Weekly</span>
                    <span v-if="act.frequency == '4'">Biweekly</span>
                    <span v-if="act.frequency == '5'">Monthly</span>
                    <span v-if="act.frequency == '6'">Quarterly</span>
                    <span v-if="act.frequency == '7'">Half Yearly</span>
                    <span v-if="act.frequency == '8'">Yearly</span>
                  </td>
                  <td class="align-center flex-display">
                    <span
                      
                      @click="ViewActivity(act._id)"
                      class="icon-box-new"
                      style="cursor:pointer;"
                    >
                      <feather-icon
                        class="pointer  rotate"
                        icon="EyeIcon"
                        size="20"
                      />
                    </span>
                    <span
                      v-b-toggle.edit-activity
                      @click="EditActivity(act._id)"
                      class="icon-box-new"
                    >
                      <feather-icon
                        class="pointer  rotate"
                        icon="Edit2Icon"
                        size="20"
                      />
                    </span>
                    <span
                      @click="openDeleteModel(act._id)"
                      class="icon-box-new"
                    >
                      <feather-icon
                        class="text-danger pointer rotate"
                        icon="Trash2Icon"
                        size="20"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-1 mr-1">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                hide-goto-end-buttons
                :total-rows="lastPage * perPage"
                class="float-right"
              />
            </div>
          </div>
        </div>
      </div>
      <b-sidebar
        id="add-activity"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="add_activity"
      >
        <div class="p-2">
          <validation-observer ref="simpleRules">
            <b-form-group label="Activity" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Activity"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Enter Activity"
                  v-model="activity"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <select name="" id="" class="form-control mt-1" v-model="frequency">
              <option value="" disabled selected>Select a frequency</option>
              <option value="1">Hourly</option>
              <option value="2">Daily</option>
              <option value="3">Weekly</option>
              <option value="4">Biweekly</option>
              <option value="5">Monthly</option>
              <option value="6">Quarterly</option>
              <option value="7">Half Yearly</option>
              <option value="8">Yearly</option>
            </select>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              class="form-control mt-1"
              placeholder="Enter Description"
              v-model="description"
            ></textarea>
            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="validationForm"
            >
              Add
            </button>
            <button class="btn btn-secondary mt-1" @click="cancelAddactivity()">
              Cancel
            </button>
          </validation-observer>
        </div>
      </b-sidebar>
      <b-sidebar
        id="edit-activity"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="edit_activity"
      >
        <!-- {{edit_activity}} -->
        <div class="p-2">
          <b-form-group label="Activity" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Enter Activity"
              v-model="activity"
            />
          </b-form-group>

          <select name="" id="" class="form-control mt-1" v-model="frequency">
            <option value="" disabled selected>Select a frequency</option>
            <option value="1">Hourly</option>
            <option value="2">Daily</option>
            <option value="3">Weekly</option>
            <option value="4">Biweekly</option>
            <option value="5">Monthly</option>
            <option value="6">Quarterly</option>
            <option value="7">Half Yearly</option>
            <option value="8">Yearly</option>
          </select>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            class="form-control mt-1"
            placeholder="Enter Description"
            v-model="description"
          ></textarea>
          <button
            class="btn btn-primary mt-1 mr-1"
            @click.prevent="validationFormUpdate"
            :disabled="!this.$store.state.auth.permissions.includes('periodic_activity-update')"
          >
            Update
          </button>
          <button class="btn btn-secondary mt-1" @click="cancelbtnUpdate()">
            Cancel
          </button>
        </div>
      </b-sidebar>
      <b-modal
        ref="modal-delete-activity"
        title="Are you sure to delete? "
        hide-footer
      >
        <button class="btn btn-danger" @click="deleteActivity(actid)" :disabled="!this.$store.state.auth.permissions.includes('periodic_activity-delete')">
          Delete Activity
        </button>

        <!-- {{label_options}} -->
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BSidebar,
  VBToggle,
  BFormInput,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  data() {
    return {
      activity: "",
      description: "",
      activities: [],
      edit_activity: false,
      activity_id: "",
      add_activity: false,
      actid: "",
      required,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      rows: 1,
      searchPeriodic: "",
      frequency: "",
    };
  },
  components: {
    BButton,
    BSidebar,
    VBToggle,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BPagination,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  watch: {
    currentPage(value) {
      this.getActivitylist(value);
    },
    searchPeriodic() {
      this.getActivitylist(this.currentPage);
    },
  },
  mounted() {
    this.getActivitylist(this.currentPage);
  },
  methods: {
    ViewActivity(id){
      this.$router.push({ name: "periodicdetails", params: { id: id } });
    },
    cancelbtnUpdate() {
      this.edit_activity = false;
    },
    cancelAddactivity() {
      this.add_activity = false;
      this.activity = "";
      this.description = "";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.addActivity();
        }
      });
    },
    validationFormUpdate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.updateActivity(this.activity_id);
        }
      });
    },

    openDeleteModel(activity) {
      this.actid = activity;
      this.$refs["modal-delete-activity"].show();
    },
    deleteActivity(actid) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${actid}`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.$refs["modal-delete-activity"].hide();
          this.getActivitylist();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addActivity() {
      const data = {
        activity: this.activity,
        description: this.description,
        frequency: this.frequency,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.add_activity = false;
          this.getActivitylist();
          (this.activity = ""), (this.description = "");
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data.data.data);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.activity[0],
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err.data.data.activity[0]);
        });
    },
    updateActivity(activityid) {
      const data = {
        activity: this.activity,
        description: this.description,
        frequency: this.frequency,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${activityid}`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.edit_activity = false;
          this.getActivitylist();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    EditActivity(activityid) {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${activityid}`,
      };
      this.$http(options)
        .then((res) => {
          this.activity = res.data.data.activity.activity;
          this.description = res.data.data.activity.description;
          this.activity_id = res.data.data.activity._id;
          this.frequency = res.data.data.activity.frequency;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivitylist(num) {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/periodic_acts?activity=${this.searchPeriodic}`,
        params: { page: num, limit: this.perPage },
      };
      this.$http(options)
        .then((res) => {
          this.activities = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;
          this.rows = this.lastPage * 10;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon-box-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
th {
  background: #472183 !important;
  color: #fff;
}
</style>
